import axios from 'axios'
import router from '@/router'
import api from '@/api'
import store from '@/store'
import { eventbus } from '@/main'
import { TokenHelper } from '@/TokenHelper'
import { Storage } from '@/Storage'
import * as Cookies from 'js-cookie'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { isIE11, isInIframe, toCapitalize } from '@/functions'
import i18n from '@/i18n'
import { User } from '@/User'
import * as Sentry from '@sentry/browser'

export let cognitoUser = null

const data = {
  data: {
    firstStageTokenData: '',
    secondStageTokenData: '',
    refreshedTokenTimestamp: 0,
    refreshTokenPending: false
  },
  custom: {
    login: {
      username: '',
      password: '',
      error: '',
      mfaChallenge: false,
      mfaCode: '',
      newPasswordChallenge: false,
      newPassword: '',
      newPasswordConfirm: '',
      stage: {
        email: true,
        password: false,
        sso: false
      },
      authOptions: []
    },
    signup: {
      id: '',
      invitation: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      marketingAgree: false,
      error: ''
    },
    selfRegistration: {
      key: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      customFields: [],
      password: '',
      passwordConfirm: '',
      orgUnit: null,
      groups: [],
      error: ''
    },
    validatekey: {
      key: '',
      error: ''
    },
    resetpassword: {
      email: '',
      code: '',
      newPassword: '',
      newPasswordConfirm: '',
      error: ''
    },
    mfa: {
      secret: '',
      code: '',
      error: ''
    },
    forceRedirect: false,
    isTrainer: false,
    userProfiles: [],
    cognitoCallbacks: {
      onSuccess: function (result) {
        const firstStageToken = result
        store.dispatch('authUserSetFirstStageToken', firstStageToken).then(() => {
          store.dispatch('authUserLoginSSO').then(() => {
            const user = new User()
            const mfaEnabled = store.getters.getAuthUserHasMfaEnabled
            const routeQuery = store.state.route.query
            const routeQueryTargetPath = routeQuery.targetPath
            const routeQueryTargetWorkspace = routeQuery.targetWorkspace

            if (routeQueryTargetWorkspace) {
              store.dispatch('authUserLoginSSOWorkspace', routeQueryTargetWorkspace).then(() => {
                router.push({ path: routeQueryTargetPath })
              }).catch(() => {
                router.push({ name: 'lms-user-workspace-list' })
              })
              return
            }

            store.dispatch('lmsUserGetWorkspaces').then(response => {
              const profiles = response.data
              const profilesWithRoles = profiles.length ? profiles.filter(obj => obj.roles.length) : []
              const userIsSuperadmin = user.isSuperadmin()
              const userIsAdmin = profiles && profiles.length ? profiles.find(obj => obj.customerRoles && obj.customerRoles.includes('master_administrator')) : false
              const userIsTrainer = profiles && profiles.length ? profiles.find(obj => obj.customerRoles && obj.customerRoles.includes('trainer')) : false

              if (profilesWithRoles.length === 0) {
                if (userIsAdmin) {
                  Cookies.set('customerRoleArea', 'master_administrator')
                } else if (userIsTrainer) {
                  Cookies.set('customerRoleArea', 'trainer')
                }

                if (userIsSuperadmin) {
                  if (mfaEnabled) {
                    router.push({ name: 'lms-superadmin-user-list' })
                  } else {
                    router.push({ name: 'lms-user-account-security' })
                  }
                } else if (userIsAdmin) {
                  router.push({ name: 'lms-superadmin-user-list' })
                } else if (userIsTrainer) {
                  router.push({ name: 'dashboard-trainer' })
                } else {
                  router.push({ name: 'lms-user-account-personal' })
                }
              } else if (profilesWithRoles.length === 1 && !userIsSuperadmin && !userIsAdmin && !userIsTrainer) {
                const workspaceId = profilesWithRoles[0] ? profilesWithRoles[0].id : undefined
                store.dispatch('authUserLoginSSOWorkspace', workspaceId).then(() => {
                  const stateRoute = store.state.route

                  if (stateRoute.name !== 'auth-login') return

                  if (userIsSuperadmin) {
                    if (mfaEnabled) {
                      router.push({ name: 'lms-superadmin-user-list' })
                    } else {
                      router.push({ name: 'lms-user-account-security' })
                    }
                  } else {
                    router.push({ name: 'default' })
                  }
                }).catch(() => {
                  router.push({ name: 'default' })
                })
              } else {
                if (userIsSuperadmin) {
                  if (mfaEnabled) {
                    router.push({ name: 'lms-superadmin-user-list' })
                  } else {
                    router.push({ name: 'lms-user-account-security' })
                  }
                } else {
                  router.push({ name: 'default' })
                }
              }
            }).catch(() => {
              router.push({ name: 'lms-user-workspace-list' })
            }).finally(() => {
              eventbus.$emit('stop-user-login')
            })
          }).catch(() => {
            router.push({ name: 'lms-user-account-personal' })
          })
        })
      },
      onFailure: function (error) {
        if (error.code === 'PasswordResetRequiredException') {
          const userEmailAddress = store.getters.getAuthUserLoginUsername
          router.push({ name: 'auth-reset-password', query: { forceResetPassword: userEmailAddress } })
          store.dispatch('defaultNotificationToastsAdd', {
            title: 'Logging in',
            message: `Your account has been forced to reset password.`,
            status: 'error'
          })
          store.dispatch('authUserSetResetPasswordEmail', userEmailAddress)
        } else {
          store.dispatch('authUserSetLoginError', error.message)
          store.dispatch('defaultNotificationToastsAdd', {
            title: 'Logging in',
            message: `Some errors occurred while logging in.`,
            status: 'error'
          })
        }
        eventbus.$emit('stop-user-login')
      },
      totpRequired: function () {
        store.dispatch('authUserSetLoginMfaChallenge', true)
        eventbus.$emit('stop-user-login')
      },
      newPasswordRequired: function () {
        store.dispatch('authUserSetLoginNewPasswordChallenge', true)
        eventbus.$emit('stop-user-login')
      }
    },
    hasEmptyRequiredCustomFields: false
  }
}

const authUser = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getAuthUserRefreshTokenPending (state) {
      return state.data.refreshTokenPending
    },
    getAuthUserRefreshedTokenTimestamp (state) {
      return state.data.refreshedTokenTimestamp
    },
    getAuthUserFirstStageTokenData (state) {
      return state.data.firstStageTokenData
    },
    getAuthUserFirstStageTokenDataName (state) {
      const data = state.data.firstStageTokenData
      const firstName = data.name
      const lastName = data.family_name
      if (firstName !== undefined && lastName !== undefined) {
        return `${toCapitalize(firstName)} ${toCapitalize(lastName)}`
      }
    },
    getAuthUserFirstStageTokenDataFirstName (state) {
      const data = state.data.firstStageTokenData
      const firstName = data.name
      return `${toCapitalize(firstName)}`
    },
    getAuthUserFirstStageTokenDataLastName (state) {
      const data = state.data.firstStageTokenData
      const lastName = data.family_name
      return `${toCapitalize(lastName)}`
    },
    getAuthUserFirstStageTokenDataEmail (state) {
      return state.data.firstStageTokenData.email
    },
    getAuthUserFirstStageTokenDataID (state) {
      const uuid = state.data.firstStageTokenData['cognito:username']
      return uuid || null
    },
    getAuthUserSecondStageTokenData (state) {
      return state.data.secondStageTokenData
    },
    getAuthUserSecondStageTokenDataExp (state) {
      return state.data.secondStageTokenData.exp
    },
    getAuthUserSecondStageTokenDataUserId (state) {
      return state.data.secondStageTokenData.userId
    },
    getAuthUserSecondStageTokenDataUserName (state) {
      const user = state.data.secondStageTokenData.user
      return user ? user.firstName + ' ' + user.lastName : ''
    },
    getAuthUserSecondStageTokenDataUserEmail (state) {
      const user = state.data.secondStageTokenData.user
      return user ? user.emailAddress : ''
    },
    getAuthUserSecondStageTokenDataOrigin (state) {
      return state.data.secondStageTokenData.origin
    },
    getAuthUserSecondStageTokenDataCustomerId (state) {
      const customer = state.data.secondStageTokenData.customer
      return customer ? customer.id : ''
    },
    getAuthUserSecondStageTokenDataCustomerName (state) {
      const customer = state.data.secondStageTokenData.customer
      return customer ? customer.name : ''
    },
    getAuthUserSecondStageTokenDataID (state) {
      const secondStageTokenData = state.data.secondStageTokenData
      let id
      if (secondStageTokenData.domain) {
        id = secondStageTokenData.uuid
      } else {
        id = secondStageTokenData.userId
      }
      return id
    },
    getAuthUserSecondStageTokenDataProfileID (state) {
      return state.data.secondStageTokenData.profile.id
    },
    getAuthUserSecondStageTokenDataDomain (state) {
      return state.data.secondStageTokenData.domain
    },
    getAuthUserSecondStageTokenDataSubdomains (state) {
      return state.data.secondStageTokenData.subdomains
    },
    getAuthUserSecondStageTokenDataWorkspaceId (state) {
      return state.data.secondStageTokenData.workspaceId
    },
    getAuthUserSecondStageTokenDataWorkspaceName (state) {
      const workspace = state.data.secondStageTokenData.workspace
      return workspace ? workspace.name : ''
    },
    getAuthUserSecondStageTokenDataOrganisationUnit (state) {
      return state.data.secondStageTokenData.organisationUnit
    },
    getAuthUserSecondStageTokenDataBackToLMSUrl (state) {
      return state.data.secondStageTokenData.lmsreturnurl
    },
    getAuthUserSecondStageTokenDataOriginUserName (state) {
      const tokenData = state.data.secondStageTokenData
      const originUser = tokenData.originUser
      const originUserName = originUser ? originUser.name : ''
      return originUserName
    },
    getAuthUserSecondStageTokenDataOriginUserNameInitials (state, getters) {
      const userNameDetails = getters.getAuthUserSecondStageTokenDataOriginUserName
      const userNameArr = userNameDetails.split(' ', 2)
      const firstName = userNameArr[0]
      const lastName = userNameArr[1]

      return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`
    },
    getAuthUserSecondStageTokenDataName (state) {
      const tokenData = state.data.secondStageTokenData
      const isOldLMSToken = tokenData.origin === 'subdomain'
      const user = tokenData ? tokenData.user : undefined
      let firstName
      let lastName

      if (isOldLMSToken) {
        firstName = tokenData ? tokenData.firstname : undefined
        lastName = tokenData ? tokenData.lastname : undefined
      } else {
        firstName = user ? user.firstName : undefined
        lastName = user ? user.lastName : undefined
      }

      return { firstName, lastName }
    },
    getAuthUserSecondStageTokenDataNameOutput (state, getters) {
      const userName = getters.getAuthUserSecondStageTokenDataName
      if (userName.firstName !== undefined && userName.lastName !== undefined) {
        return `${toCapitalize(userName.firstName)} ${toCapitalize(userName.lastName)}`
      }
    },
    getAuthUserSecondStageTokenDataNameInitials (state, getters) {
      const userName = getters.getAuthUserSecondStageTokenDataName
      if (userName.firstName !== undefined && userName.lastName !== undefined) {
        return userName.firstName[0] + userName.lastName[0]
      }
    },
    getAuthUserSecondStageTokenDataProfileRolesFiltered (state) {
      const profile = state.data.secondStageTokenData.profile
      const profileRolesRaw = profile && profile.roles ? profile.roles : []
      let profileRolesOutput = profileRolesRaw.filter(obj => obj === 'admin' || obj === 'learner' || obj === 'manager')

      return profileRolesOutput
    },
    getAuthUserIsSecondStageTokenOldLMS (state) {
      const token = TokenHelper.getToken('secondStageToken')
      const tokenPayload = token ? TokenHelper.getPayload(token) : state.data.secondStageTokenData
      return tokenPayload ? tokenPayload.origin === 'subdomain' : ''
    },
    getAuthUserIsSecondStageTokenNewLMS (state) {
      const token = TokenHelper.getToken('secondStageToken')
      const tokenPayload = token ? TokenHelper.getPayload(token) : state.data.secondStageTokenData
      return tokenPayload ? tokenPayload.origin === 'workspace' : ''
    },
    getAuthUserHasMfaRequired (state) {
      const mfaRequired = state.data.firstStageTokenData['custom:mfa_required'] === 'true'
      const firstStageToken = Boolean(state.data.firstStageTokenData)
      const devMode = Cookies.get('devMode')
      return mfaRequired && firstStageToken && !devMode
    },
    getAuthUserHasMfaEnabled (state) {
      const mfaRequired = state.data.firstStageTokenData['custom:mfa_enabled'] === 'true'
      const firstStageToken = Boolean(state.data.firstStageTokenData)
      return mfaRequired && firstStageToken
    },
    getAuthUserHasEmptyRequiredCustomFields (state) {
      return state.custom.hasEmptyRequiredCustomFields
    },
    //
    getAuthUserLoginUsername (state) {
      return state.custom.login.username
    },
    getAuthUserLoginPassword (state) {
      return state.custom.login.password
    },
    getAuthUserLoginError (state) {
      return state.custom.login.error
    },
    getAuthUserLoginMfaChallenge (state) {
      return state.custom.login.mfaChallenge
    },
    getAuthUserLoginMfaCode (state) {
      return state.custom.login.mfaCode
    },
    getAuthUserLoginNewPasswordChallenge (state) {
      return state.custom.login.newPasswordChallenge
    },
    getAuthUserLoginNewPassword (state) {
      return state.custom.login.newPassword
    },
    getAuthUserLoginNewPasswordConfirm (state) {
      return state.custom.login.newPasswordConfirm
    },
    getAuthUserLoginStage (state) {
      return state.custom.login.stage
    },
    getAuthUserLoginAuthOptions (state) {
      return state.custom.login.authOptions
    },
    getAuthUserResetPasswordEmail (state) {
      return state.custom.resetpassword.email
    },
    getAuthUserResetPasswordCode (state) {
      return state.custom.resetpassword.code
    },
    getAuthUserResetPasswordNewPassword (state) {
      return state.custom.resetpassword.newPassword
    },
    getAuthUserResetPasswordNewPasswordConfirm (state) {
      return state.custom.resetpassword.newPasswordConfirm
    },
    getAuthUserResetPasswordError (state) {
      return state.custom.resetpassword.error
    },
    getAuthUserMfaSecret (state) {
      return state.custom.mfa.secret
    },
    getAuthUserMfaCode (state) {
      return state.custom.mfa.code
    },
    getAuthUserMfaError (state) {
      return state.custom.mfa.error
    },
    getAuthUserSignUpId (state) {
      return state.custom.signup.id
    },
    getAuthUserSignUpInvitation (state) {
      return state.custom.signup.invitation
    },
    getAuthUserSignUpEmail (state) {
      return state.custom.signup.email
    },
    getAuthUserSignUpFirstName (state) {
      return state.custom.signup.firstName
    },
    getAuthUserSignUpLastName (state) {
      return state.custom.signup.lastName
    },
    getAuthUserSignUpPassword (state) {
      return state.custom.signup.password
    },
    getAuthUserSignUpPasswordConfirm (state) {
      return state.custom.signup.passwordConfirm
    },
    getAuthUserSignUpMarketingAgree (state) {
      return state.custom.signup.marketingAgree
    },
    getAuthUserSignUpError (state) {
      return state.custom.signup.error
    },
    getAuthUserValidateKeyKey (state) {
      return state.custom.validatekey.key
    },
    getAuthUserValidateKeyError (state) {
      return state.custom.validatekey.error
    },
    getAuthUserSelfRegistrationKey (state) {
      return state.custom.selfRegistration.key
    },
    getAuthUserSelfRegistrationEmailAddress (state) {
      return state.custom.selfRegistration.emailAddress
    },
    getAuthUserSelfRegistrationFirstName (state) {
      return state.custom.selfRegistration.firstName
    },
    getAuthUserSelfRegistrationLastName (state) {
      return state.custom.selfRegistration.lastName
    },
    getAuthUserSelfRegistrationPassword (state) {
      return state.custom.selfRegistration.password
    },
    getAuthUserSelfRegistrationPasswordConfirm (state) {
      return state.custom.selfRegistration.passwordConfirm
    },
    getAuthUserSelfRegistrationOrgUnit (state) {
      const rawOrgUnit = state.custom.selfRegistration.orgUnit
      return rawOrgUnit
    },
    getAuthUserSelfRegistrationGroups (state) {
      return state.custom.selfRegistration.groups
    },
    getAuthUserSelfRegistrationCustomFields (state) {
      return state.custom.selfRegistration.customFields
    },
    getAuthUserSelfRegistrationError (state) {
      return state.custom.selfRegistration.error
    },
    getAuthUserForceRedirect (state) {
      return state.custom.forceRedirect
    },
    //
    getAuthUserIsTrainer (state) {
      return state.custom.isTrainer
    }
  },
  mutations: {
    AUTH_USER_SET_REFRESH_TOKEN_PENDING (state, payload) {
      state.data.refreshTokenPending = payload
    },
    AUTH_USER_SET_REFRESHED_TOKEN_TIMESTAMP (state, payload) {
      state.data.refreshedTokenTimestamp = payload
      state.data.refreshTokenPending = false
    },
    AUTH_USER_SET_FIRSTSTAGETOKEN (state, payload) {
      if (isIE11()) {
        Storage.setSession('firstStageToken', payload.idToken.jwtToken)
        Storage.setSession('accessToken', payload.accessToken.jwtToken)
        Storage.setSession('refreshToken', payload.refreshToken.token)
      }
      let cookieOptions = {}
      if (isInIframe()) {
        cookieOptions = {
          secure: true,
          sameSite: 'none'
        }
      }
      Cookies.set('firstStageToken', payload.idToken.jwtToken, cookieOptions)
      Cookies.set('accessToken', payload.accessToken.jwtToken, cookieOptions)
      Cookies.set('refreshToken', payload.refreshToken.token, cookieOptions)
    },
    AUTH_USER_SET_FIRSTSTAGETOKENDATA (state, payload) {
      state.data.firstStageTokenData = payload
    },
    AUTH_USER_SET_SECONDSTAGETOKEN (state, payload) {
      if (isIE11()) {
        Storage.setSession('secondStageToken', payload)
      }
      let cookieOptions = {}
      if (isInIframe()) {
        cookieOptions = {
          secure: true,
          sameSite: 'none'
        }
      }
      Cookies.set('secondStageToken', payload, cookieOptions)
    },
    AUTH_USER_SET_SECONDSTAGETOKENDATA (state, payload) {
      state.data.secondStageTokenData = payload
    },
    AUTH_USER_SET_LOGIN_USERNAME (state, payload) {
      state.custom.login.username = payload
    },
    AUTH_USER_SET_LOGIN_PASSWORD (state, payload) {
      state.custom.login.password = payload
    },
    AUTH_USER_SET_LOGIN_ERROR (state, payload) {
      state.custom.login.error = payload
    },
    AUTH_USER_SET_LOGIN_MFACHALLENGE (state, payload) {
      state.custom.login.mfaChallenge = payload
    },
    AUTH_USER_SET_LOGIN_MFACODE (state, payload) {
      state.custom.login.mfaCode = payload
    },
    AUTH_USER_SET_LOGIN_NEWPASSWORDCHALLENGE (state, payload) {
      state.custom.login.newPasswordChallenge = payload
    },
    AUTH_USER_SET_LOGIN_NEWPASSWORD (state, payload) {
      state.custom.login.newPassword = payload
    },
    AUTH_USER_SET_LOGIN_NEWPASSWORDCONFIRM (state, payload) {
      state.custom.login.newPasswordConfirm = payload
    },
    AUTH_USER_SET_LOGIN_STAGE (state, payload) {
      if (payload === 'email') {
        state.custom.login.stage = {
          email: true,
          password: false,
          sso: false
        }
      } else if (payload === 'password') {
        state.custom.login.stage = {
          email: false,
          password: true,
          sso: false
        }
      } else if (payload === 'sso') {
        state.custom.login.stage = {
          email: false,
          password: false,
          sso: true
        }
      }
    },
    AUTH_USER_SET_LOGIN_AUTHOPTIONS (state, payload) {
      state.custom.login.authOptions = payload
    },
    AUTH_USER_SET_RESETPASSWORD_EMAIL (state, payload) {
      state.custom.resetpassword.email = payload
    },
    AUTH_USER_SET_RESETPASSWORD_CODE (state, payload) {
      state.custom.resetpassword.code = payload
    },
    AUTH_USER_SET_RESETPASSWORD_NEWPASSWORD (state, payload) {
      state.custom.resetpassword.newPassword = payload
    },
    AUTH_USER_SET_RESETPASSWORD_NEWPASSWORDCONFIRM (state, payload) {
      state.custom.resetpassword.newPasswordConfirm = payload
    },
    AUTH_USER_SET_RESETPASSWORD_ERROR (state, payload) {
      state.custom.resetpassword.error = payload
    },
    AUTH_USER_SET_MFA_SECRET (state, payload) {
      state.custom.mfa.secret = payload
    },
    AUTH_USER_SET_MFA_CODE (state, payload) {
      state.custom.mfa.code = payload
    },
    AUTH_USER_SET_MFA_ERROR (state, payload) {
      state.custom.mfa.error = payload
    },
    AUTH_USER_SET_SIGNUP_INVITATION (state, payload) {
      state.custom.signup.invitation = payload
    },
    AUTH_USER_SET_SIGNUP_ID (state, payload) {
      state.custom.signup.id = payload
    },
    AUTH_USER_SET_SIGNUP_EMAIL (state, payload) {
      state.custom.signup.email = payload
    },
    AUTH_USER_SET_SIGNUP_FIRSTNAME (state, payload) {
      state.custom.signup.firstName = payload
    },
    AUTH_USER_SET_SIGNUP_LASTNAME (state, payload) {
      state.custom.signup.lastName = payload
    },
    AUTH_USER_SET_SIGNUP_PASSWORD (state, payload) {
      state.custom.signup.password = payload
    },
    AUTH_USER_SET_SIGNUP_PASSWORDCONFIRM (state, payload) {
      state.custom.signup.passwordConfirm = payload
    },
    AUTH_USER_SET_SIGNUP_MARKETINGAGREE (state, payload) {
      state.custom.signup.marketingAgree = payload
    },
    AUTH_USER_SET_SIGNUP_ERROR (state, payload) {
      state.custom.signup.error = payload
    },
    AUTH_USER_SET_VALIDATEKEY_KEY (state, payload) {
      state.custom.validatekey.key = payload
    },
    AUTH_USER_SET_VALIDATEKEY_ERROR (state, payload) {
      state.custom.validatekey.error = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_KEY (state, payload) {
      state.custom.selfRegistration.key = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_EMAILADDRESS (state, payload) {
      state.custom.selfRegistration.emailAddress = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_FIRSTNAME (state, payload) {
      state.custom.selfRegistration.firstName = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_LASTNAME (state, payload) {
      state.custom.selfRegistration.lastName = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_CUSTOMFIELD (state, payload) {
      let customFields = JSON.parse(JSON.stringify(state.custom.selfRegistration.customFields))
      let existingCustomFieldIndex = customFields.findIndex(obj => obj.id === payload.id)

      if (existingCustomFieldIndex > -1) {
        if (payload.value !== '') {
          customFields[existingCustomFieldIndex].value = payload.value
        } else {
          customFields.splice(existingCustomFieldIndex, 1)
        }
      } else {
        customFields.push({
          id: payload.id,
          value: payload.value
        })
      }

      state.custom.selfRegistration.customFields = customFields
    },
    AUTH_USER_SET_SELFREGISTRATION_PASSWORD (state, payload) {
      state.custom.selfRegistration.password = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_PASSWORDCONFIRM (state, payload) {
      state.custom.selfRegistration.passwordConfirm = payload
    },
    AUTH_USER_SET_SELFREGISTRATION_ORGUNIT (state, payload) {
      state.custom.selfRegistration.orgUnit = payload.id
    },
    AUTH_USER_SET_SELFREGISTRATION_GROUPS (state, payload) {
      state.custom.selfRegistration.groups = payload.map(obj => obj.value)
    },
    AUTH_USER_SET_SELFREGISTRATION_ERROR (state, payload) {
      state.custom.selfRegistration.error = payload
    },
    AUTH_USER_SET_FORCEREDIRECT (state, payload) {
      state.custom.forceRedirect = payload
    },
    AUTH_USER_SET_HASEMPTYREQUIREDCUSTOMFIELDS (state, payload) {
      state.custom.hasEmptyRequiredCustomFields = payload
    },
    //
    AUTH_USER_REMOVE_FIRSTSTAGETOKENDATA (state) {
      state.data.firstStageTokenData = ''
      if (isIE11()) {
        Storage.removeSession('firstStageToken')
        Storage.removeSession('accessToken')
        Storage.removeSession('refreshToken')
      }
      Cookies.remove('firstStageToken')
      Cookies.remove('accessToken')
      Cookies.remove('refreshToken')
      for (let key of Storage.keysLocal()) {
        if (key.includes('CognitoIdentityServiceProvider')) {
          Storage.removeLocal(key)
        }
      }
    },
    AUTH_USER_REMOVE_SECONDSTAGETOKENDATA (state) {
      state.data.secondStageTokenData = ''
      if (isIE11()) {
        Storage.removeSession('secondStageToken')
      }
      Cookies.remove('isSSO')
      Cookies.remove('secondStageToken')
      Cookies.remove('customerRoleArea')
    },
    //
    AUTH_USER_CLEAR_LOGIN (state) {
      state.custom.login = JSON.parse(JSON.stringify(data.custom.login))
    },
    AUTH_USER_CLEAR_LOGIN_ERROR (state) {
      state.custom.login.error = ''
    },
    AUTH_USER_CLEAR_VALIDATEKEY (state) {
      state.custom.validatekey = JSON.parse(JSON.stringify(data.custom.validatekey))
    },
    AUTH_USER_CLEAR_VALIDATEKEY_ERROR (state) {
      state.custom.validatekey.error = ''
    },
    AUTH_USER_CLEAR_SIGNUP (state) {
      state.custom.signup = JSON.parse(JSON.stringify(data.custom.signup))
    },
    AUTH_USER_CLEAR_SIGNUP_ERROR (state) {
      state.custom.signup.error = ''
    },
    AUTH_USER_CLEAR_SELFREGISTRATION (state) {
      state.custom.selfRegistration = JSON.parse(JSON.stringify(data.custom.selfRegistration))
    },
    AUTH_USER_CLEAR_SELFREGISTRATION_ERROR (state) {
      state.custom.selfRegistration.error = ''
    },
    AUTH_USER_CLEAR_RESETPASSWORD (state) {
      state.custom.resetpassword = JSON.parse(JSON.stringify(data.custom.resetpassword))
    },
    AUTH_USER_CLEAR_RESETPASSWORD_ERROR (state) {
      state.custom.resetpassword.error = ''
    },
    //
    AUTH_USER_SET_ISTRAINER (state, payload) {
      state.custom.isTrainer = payload
    }
  },
  actions: {
    authUserSetFirstStageToken ({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data) {
          commit('AUTH_USER_SET_FIRSTSTAGETOKEN', data)
          commit('AUTH_USER_SET_FIRSTSTAGETOKENDATA', TokenHelper.getPayload(data.idToken.jwtToken))
          eventbus.$emit('set-first-stage-token')
          resolve(data.idToken.jwtToken)
        } else {
          const firstStageToken = TokenHelper.getToken('firstStageToken')
          if (firstStageToken) {
            const firstStageTokenPayload = TokenHelper.getPayload(firstStageToken)
            commit('AUTH_USER_SET_FIRSTSTAGETOKENDATA', firstStageTokenPayload)
            eventbus.$emit('set-first-stage-token')
            resolve(firstStageToken)
          } else {
            reject()
          }
        }
      })
    },
    authUserSetSecondStageToken ({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data) {
          const secondStageTokenPayload = TokenHelper.getPayload(data)
          commit('AUTH_USER_SET_SECONDSTAGETOKEN', data)
          commit('AUTH_USER_SET_SECONDSTAGETOKENDATA', secondStageTokenPayload)
          commit('AUTH_USER_SET_REFRESHED_TOKEN_TIMESTAMP', Date.now())
          resolve(data)
        } else {
          const secondStageToken = TokenHelper.getToken('secondStageToken')
          if (secondStageToken) {
            const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
            commit('AUTH_USER_SET_SECONDSTAGETOKENDATA', secondStageTokenPayload)
            commit('AUTH_USER_SET_REFRESHED_TOKEN_TIMESTAMP', Date.now())
            resolve(secondStageToken)
          } else {
            reject()
          }
        }
      })
    },
    authUserInitializeTokens ({ dispatch }) {
      return new Promise((resolve, reject) => {
        const windowHref = window.location.href

        if (
          windowHref.includes('?token=') ||
        windowHref.includes('?invitation=') ||
        windowHref.includes('?source=') ||
        windowHref.includes('?registerKey=') ||
        windowHref.includes('/auth/') ||
        windowHref.match(/\/report\/[\S]+\/result\/[\S]+/)
        ) {
          return
        }
        let secondStageToken = TokenHelper.getToken('secondStageToken')
        let secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
        let isSecondStageTokenFromOldLMS = secondStageTokenPayload ? secondStageTokenPayload.origin === 'subdomain' : false

        if (!isSecondStageTokenFromOldLMS) {
          dispatch('authUserValidateFirstStageToken', { preventAny: true })
        }
        dispatch('authUserValidateSecondStageToken', { preventAny: true }).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserInitializeProfiles ({ dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        const user = new User()
        const userProfiles = getters.getLmsSuperadminUserProfiles
        const force = data ? (data.force !== undefined ? data.force : false) : false
        let isSecondStageTokenFromNewLMS = user.isTokenFromNewLms()

        if ((!userProfiles || force) && isSecondStageTokenFromNewLMS) {
          dispatch('authUserValidateFirstStageToken', { preventAny: true }).then(() => {
            dispatch('authUserValidateSecondStageToken', { preventAny: true }).then(() => {
              dispatch('lmsSuperadminUserProfilesGet').then(() => {
                resolve()
              })
            })
          }).catch(error => {
            reject(error)
          })
        } else {
          resolve()
        }
      })
    },
    authUserInitializeRequests ({ dispatch, getters }, data) {
      return new Promise((resolve) => {
        const user = new User()
        const routeTo = data && data.to ? data.to.name : ''
        const secondStageToken = TokenHelper.getToken('secondStageToken')
        const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
        const currentWorkspaceId = secondStageTokenPayload ? secondStageTokenPayload.workspaceId : undefined
        const requestsForWorkspaceId = getters.getEbsRequestsCustomResultsForWorkspaceId

        if (
          routeTo !== 'ebs-requests-list' &&
        secondStageToken &&
        currentWorkspaceId &&
        (
          user.isManager() ||
          user.isLearningExperienceAdministrator()
        ) &&
        (
          !requestsForWorkspaceId ||
          requestsForWorkspaceId !== currentWorkspaceId
        )
        ) {
          dispatch('ebsRequestsGet', { queryUrlParams: '?pageSize=1' }).finally(() => {
            resolve()
          })
        }
      })
    },
    authUserRefreshFirstStageToken ({ dispatch }) {
      return new Promise((resolve, reject) => {
        const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
        const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID
        const poolData = {
          UserPoolId: userPoolId,
          ClientId: clientId
        }
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const cognitoUser = userPool.getCurrentUser()
        const refreshToken = TokenHelper.getToken('refreshToken')
        const refreshTokenObj = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken })

        cognitoUser.refreshSession(refreshTokenObj, (err, data) => {
          if (err) {
            reject(new Error('errorExecuteRefreshToken'))
          }
          dispatch('authUserSetFirstStageToken', data).then(() => {
            resolve(data)
          })
        })
      })
    },
    authUserRefreshSecondStageToken ({ dispatch, getters, commit }, data) {
      return new Promise((resolve, reject) => {
        const refreshTokenPending = getters.getAuthUserRefreshTokenPending
        const refreshedTokenTimestamp = JSON.parse(JSON.stringify(getters.getAuthUserRefreshedTokenTimestamp))
        const currentTimestamp = Date.now()
        const timestampDifferenceInSeconds = refreshedTokenTimestamp && refreshedTokenTimestamp !== 0 ? (Math.floor(currentTimestamp / 1000) - Math.floor(refreshedTokenTimestamp / 1000)) : null
        const secondStageToken = getters.getAuthUserSecondStageTokenData

        if (timestampDifferenceInSeconds && timestampDifferenceInSeconds < 1 || refreshTokenPending) {
          return reject()
        }

        const userFromOldLMS = getters.getAuthUserIsSecondStageTokenOldLMS
        const secondStageTokenWorkspaceId = data || getters.getAuthUserSecondStageTokenDataWorkspaceId

        if (userFromOldLMS || !secondStageToken) return reject()

        commit('AUTH_USER_SET_REFRESH_TOKEN_PENDING', true)

        if (secondStageTokenWorkspaceId) {
          dispatch('authUserRefreshWorkspaceToken', secondStageTokenWorkspaceId).then(response => {
            commit('AUTH_USER_SET_REFRESHED_TOKEN_TIMESTAMP', Date.now())
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        } else {
          dispatch('authUserLoginSSO').then(() => {
            commit('AUTH_USER_SET_REFRESHED_TOKEN_TIMESTAMP', Date.now())
            resolve()
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    authUserRemoveFirstStageToken ({ commit }) {
      commit('AUTH_USER_REMOVE_FIRSTSTAGETOKENDATA')
    },
    authUserRemoveSecondStageToken ({ commit, dispatch }) {
      commit('AUTH_USER_REMOVE_SECONDSTAGETOKENDATA')
      dispatch('defaultWorkspaceClear')
    },
    authUserValidateFirstStageToken ({ getters, dispatch }, data) {
      const user = new User()
      const isFromSSO = user.isTokenFromSSO()
      const preventAny = data ? data.preventAny : false
      const preventRedirect = data ? data.preventRedirect : false
      const preventNotification = data ? data.preventNotification : false
      const preventHoldOnReject = data ? data.preventHoldOnReject : false

      const firstStageToken = TokenHelper.getToken('firstStageToken') || Storage.getSession('firstStageToken')
      const firstStageTokenData = getters.getAuthUserFirstStageTokenData
      const secondStageToken = TokenHelper.getToken('secondStageToken') || Storage.getSession('secondStageToken')

      const performLogOut = () => {
        dispatch('authUserLogout', {
          preventNotification: !preventNotification,
          preventRedirect: !preventRedirect,
          preventHoldOnReject: !preventHoldOnReject,
          source: { name: 'authUserValidateFirstStageToken', type: 'errorRefreshingFirstStageToken' }
        })
      }

      return new Promise((resolve, reject) => {
        if (isFromSSO) {
          return resolve('userFromSSO')
        }

        if (preventAny && !firstStageToken && !firstStageTokenData) {
          reject('emptyFirstStageToken')
        }

        if (firstStageToken) {
          const firstStageTokenPayload = TokenHelper.getPayload(firstStageToken)
          const firstStageTokenExp = firstStageTokenPayload.exp
          const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
          const secondStageTokenExp = secondStageTokenPayload ? secondStageTokenPayload.exp : null
          const timeIsExpired = Date.now() / 1000
          const timeDifferenceInMinutes = secondStageTokenExp && timeIsExpired ? new Date((secondStageTokenExp - timeIsExpired) * 1000).getMinutes() : 0

          if (firstStageTokenExp < timeIsExpired) {
            if (secondStageTokenExp > timeIsExpired) {
              dispatch('authUserRefreshFirstStageToken').then(data => {
                resolve(data.idToken.jwtToken)
              }).catch(() => {
                performLogOut()
                if (!preventNotification && !preventAny && timeDifferenceInMinutes < 20) {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Logging out',
                    message: `You have been logged out due to inactivity. \nPlease login again to continue.`,
                    status: 'success'
                  })
                }
                if (!preventRedirect && !preventAny) {
                  dispatch('authUserSetForceRedirect', true)
                  router.push({ name: 'auth-login' })
                }
                reject(new Error('errorRefreshingFirstStageToken'))
              })
            } else {
              performLogOut()
              if (!preventNotification && !preventAny && timeDifferenceInMinutes > 20) {
                dispatch('defaultNotificationToastsAdd', {
                  title: 'Logging out',
                  message: `You have been logged out due to inactivity. \nPlease login again to continue.`,
                  status: 'success'
                })
              }
              if (!preventRedirect && !preventAny) {
                dispatch('authUserSetForceRedirect', true)
                router.push({ name: 'auth-login' })
              }
              reject(new Error('errorExpiredSecondStageToken'))
            }
          } else {
            if (firstStageTokenData) {
              resolve(firstStageToken)
            } else {
              dispatch('authUserSetFirstStageToken').then((data) => {
                resolve(data)
              }).catch((error) => {
                if (!preventNotification && !preventAny) {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Logging out',
                    message: `You have been redirected due to token problems.`,
                    status: 'success'
                  })
                }
                if (!preventRedirect && !preventAny) {
                  dispatch('authUserSetForceRedirect', true)
                  router.push({ name: 'auth-login' })
                }
                reject(error)
              })
            }
          }
        } else {
          performLogOut()
          if (!preventRedirect && !preventAny) {
            dispatch('authUserSetForceRedirect', true)
            router.push({ name: 'auth-login' })
          }
          reject()
        }
      })
    },
    authUserValidateSecondStageToken ({ getters, dispatch }, data) {
      const preventAny = data ? data.preventAny : false
      const preventRedirect = data ? data.preventRedirect : false
      const preventNotification = data ? data.preventNotification : false
      const preventHoldOnReject = data ? data.preventHoldOnReject : false
      const allowLogoutOldLMS = data ? data.allowLogoutOldLMS : false

      const secondStageToken = TokenHelper.getToken('secondStageToken') || Storage.getSession('secondStageToken')
      const secondStageTokenData = getters.getAuthUserSecondStageTokenData

      return new Promise((resolve, reject) => {
        if (preventAny && !secondStageToken && !secondStageTokenData) {
          reject()
        }

        if (secondStageToken) {
          const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
          const secondStageTokenExp = secondStageTokenPayload.exp
          const timeIsExpired = Date.now() / 1000

          if (secondStageTokenExp < timeIsExpired) {
            dispatch('authUserValidateFirstStageToken', { preventAny: true }).then(() => {
              dispatch('authUserRefreshSecondStageToken').then(response => {
                resolve(response)
              })
            }).catch(() => {
              dispatch('authUserLogoutSecondStage', { preventNotification: !preventNotification, preventRedirect: !preventRedirect, preventHoldOnReject: !preventHoldOnReject, allowLogoutOldLMS: allowLogoutOldLMS })
              if (!preventNotification && !preventAny) {
                dispatch('defaultNotificationToastsAdd', {
                  title: 'Workspace session',
                  message: `Your workspace session has expired.`,
                  status: 'error'
                })
              }
              if (!preventRedirect && !preventAny) {
                dispatch('authUserSetForceRedirect', true)
                router.push({ name: 'lms-user-workspace-list' })
              }
              reject(new Error('expiredSecondStageToken'))
            })
          } else {
            if (secondStageTokenData) {
              resolve(secondStageToken)
            } else {
              dispatch('authUserSetSecondStageToken').then((data) => {
                resolve(data)
              }).catch((error) => {
                if (!preventNotification && !preventAny) {
                  dispatch('defaultNotificationToastsAdd', {
                    title: 'Workspace session',
                    message: `You have been redirected due to session configuration problems.`,
                    status: 'error'
                  })
                }
                if (!preventRedirect && !preventAny) {
                  dispatch('authUserSetForceRedirect', true)
                  router.push({ name: 'lms-user-workspace-list' })
                }
                reject(new Error(error))
              })
            }
          }
        } else {
          dispatch('authUserLogoutSecondStage', { preventNotification: !preventNotification, preventRedirect: !preventRedirect, preventHoldOnReject: !preventHoldOnReject })
          if (!preventNotification && !preventAny) {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Workspace session',
              message: `You have been redirected due to a missing session.`,
              status: 'error'
            })
          }
          if (!preventRedirect && !preventAny) {
            dispatch('authUserSetForceRedirect', true)
            router.push({ name: 'lms-user-workspace-list' })
          }
          reject()
        }
      })
    },
    authUserCheckExistsSSO (ctx, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.sso}/v2/user/email`,
          data: {
            emailAddress: data
          }
        }).then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(new Error('userNotExists'))
          }
        }).catch(() => {
          reject(new Error('userNotExists'))
        })
      })
    },
    authUserCheckExistsSSOSimplified (ctx, data) {
      return new Promise((resolve, reject) => {
        if (!data) return reject(new Error('missingEmailAddress'))

        axios({
          method: 'get',
          url: `${api.sso}/v3/users/exists?email=${encodeURIComponent(data)}`
        }).then(response => {
          if (response.data.exists) {
            resolve(response.data.exists)
          } else {
            reject(new Error('userNotExists'))
          }
        }).catch(() => {
          reject(new Error('requestError'))
        })
      })
    },
    authUserCheckLoginMethods (ctx, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.subscriptionmanager}/saml/login-methods`,
          data: {
            email: data
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserCheckHasEmptyRequiredCustomFields (ctx, workspaceId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.sso}/v2/all-custom-fields-filled-in/${workspaceId}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //
    authUserClearLogin ({ commit }) {
      commit('AUTH_USER_CLEAR_LOGIN')
    },
    authUserClearValidateKey ({ commit }) {
      commit('AUTH_USER_CLEAR_VALIDATEKEY')
    },
    authUserClearSelfRegistration ({ commit }) {
      commit('AUTH_USER_CLEAR_SELFREGISTRATION')
    },
    authUserClearSignup ({ commit }) {
      commit('AUTH_USER_CLEAR_SIGNUP')
    },
    authUserClearResetpassword ({ commit }) {
      commit('AUTH_USER_CLEAR_RESETPASSWORD')
    },
    authUserLoginCognito ({ commit, getters }) {
      commit('AUTH_USER_REMOVE_FIRSTSTAGETOKENDATA')
      commit('AUTH_USER_REMOVE_SECONDSTAGETOKENDATA')
      commit('AUTH_USER_CLEAR_LOGIN_ERROR')
      commit('LMS_SUPERADMIN_USER_PROFILES_CLEAR')

      const username = getters.getAuthUserLoginUsername
      const password = getters.getAuthUserLoginPassword
      const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
      const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID

      const authenticationData = {
        Username: username,
        Password: password
      }
      const poolData = {
        UserPoolId: userPoolId,
        ClientId: clientId
      }
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      const userData = {
        Username: username,
        Pool: userPool
      }
      cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      cognitoUser.authenticateUser(authenticationDetails, data.custom.cognitoCallbacks)
    },
    authUserLoginSAML (ctx, subscriptionId) {
      return new Promise((resolve, reject) => {
        return axios({
          method: 'get',
          url: `${api.subscriptionmanager}/saml/login/${subscriptionId}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserLoginCognitoMFA ({ getters }) {
      const mfaCode = getters.getAuthUserLoginMfaCode
      cognitoUser.sendMFACode(mfaCode, data.custom.cognitoCallbacks, 'SOFTWARE_TOKEN_MFA')
    },
    authUserLoginSSO ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        return axios({
          method: 'post',
          url: `${api.sso}/v2/exchange/token`
        }).then(response => {
          const token = response.data.token
          commit('AUTH_USER_SET_REFRESHED_TOKEN_TIMESTAMP', Date.now())
          dispatch('ebsRequestsClear')
          dispatch('authUserSetSecondStageToken', token).then(() => {
            resolve(token)
          })
          dispatch('authUserInitializeRequests')
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserCountLogin (ctx, data) {
      return new Promise((resolve, reject) => {
        const userId = data && data.userId ? data.userId : null
        const workspaceId = data && data.workspaceId ? data.workspaceId : null

        if (!userId || !workspaceId) return reject()

        return axios({
          method: 'post',
          url: `${api.sso}/v2/user/count-login`,
          data: {
            userId,
            workspaceId
          }
        }).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserRefreshWorkspaceToken ({ dispatch, getters }, value) {
      return new Promise((resolve, reject) => {
        const workspaceId = value || getters.getAuthUserSecondStageTokenDataWorkspaceId

        if (!workspaceId) return reject(new Error())

        return axios({
          method: 'post',
          url: `${api.sso}/v2/validate/workspace/${workspaceId}`,
          data: {
            token: TokenHelper.getToken('secondStageToken')
          }
        }).then(response => {
          const token = response.data
          dispatch('authUserSetSecondStageToken', token).then(() => {
            resolve(token)
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserLoginSSOWorkspace ({ dispatch }, value) {
      return new Promise((resolve, reject) => {
        const workspaceId = value
        return axios({
          method: 'post',
          url: `${api.sso}/v2/validate/workspace/${workspaceId}`,
          data: {
            token: TokenHelper.getToken('secondStageToken')
          }
        }).then(response => {
          const token = response.data
          dispatch('lmsSuperadminWorkspaceClear')
          dispatch('authUserSetSecondStageToken', token).then(() => {
            const user = new User()
            const userId = user.userId

            if (workspaceId) {
              dispatch('authUserCountLogin', { userId, workspaceId }).catch(() => {})
              dispatch('defaultCustomerOptionsGetOptions', { force: true })
              dispatch('lmsSuperadminCustomerGetCCSSettings', { force: true, customerId: user.customerId })
              dispatch('defaultWorkspaceGet', workspaceId).finally(() => {
                dispatch('lmsSuperadminWorkspaceGetApiIntegrationState', workspaceId).then(response => {
                  const api = response.api

                  if (!api) {
                    dispatch('authUserCheckHasEmptyRequiredCustomFields', workspaceId).then(response => {
                      const allFilledIn = response ? response.allFilledIn : false
                      if (allFilledIn) {
                        dispatch('authUserSetHasEmptyRequiredCustomFields', false)
                        resolve()
                        eventbus.$emit('workspace-access')
                      } else {
                        dispatch('authUserSetHasEmptyRequiredCustomFields', true)
                        router.push({ name: 'lms-user-account-personal' }).catch(() => {})
                        reject(new Error('emptyUserFields'))
                      }
                    })
                  } else {
                    resolve()
                  }
                }).catch(error => {
                  reject(new Error(error))
                })
              })
            } else {
              resolve(token)
              eventbus.$emit('workspace-access')
            }
            if (user.isLearningExperienceAdministrator()) {
              dispatch('ebsReportDashboardResetFilters')
            }
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        }).finally(() => {
          Cookies.remove('customerRoleArea')
        })
      })
    },
    authUserLoginCognitoNewPassword ({ getters }) {
      const newPassword = getters.getAuthUserLoginNewPassword
      const email = getters.getAuthUserLoginUsername
      cognitoUser.completeNewPasswordChallenge(newPassword, { email }, data.custom.cognitoCallbacks)
    },
    authUserLogout ({ commit, dispatch }, data) {
      const preventRedirect = data ? data.preventRedirect : false
      const source = data ? data.source : false

      // check if user uses old LMS token and if so logout him with redirection to LMS
      let secondStageToken = TokenHelper.getToken('secondStageToken')
      let secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
      let isSecondStageTokenFromOldLMS = secondStageTokenPayload ? secondStageTokenPayload.origin === 'subdomain' : false

      if (source && source.name === 'authUserValidateFirstStageToken') {
        Sentry.captureMessage(`authUserLogout | ${source.name} - ${source.type}`, 'warning')
      }

      if (isSecondStageTokenFromOldLMS) {
        dispatch('authUserLogoutSecondStage', { allowLogoutOldLMS: true })
        return
      }

      dispatch('authUserRemoveFirstStageToken')
      dispatch('authUserRemoveSecondStageToken')

      commit('CLEAR_BOOKING')
      commit('EBS_COURSE_CLEAR')
      commit('EBS_COURSES_CLEAR')
      commit('CLEAR_EMAIL')
      commit('CLEAR_EVENT')
      commit('CLEAR_EVENTS')
      commit('CLEAR_REGISTER')
      commit('CLEAR_REPORT')
      commit('CLEAR_ROOM')
      commit('CLEAR_ROOMS')
      commit('CLEAR_SERVICES')
      commit('CLEAR_SESSION')
      commit('CLEAR_SUBSCRIPTION')
      commit('CLEAR_SUMMARY')
      commit('CLEAR_TRAINER')
      commit('CLEAR_TRAINERS')
      commit('CLEAR_USERS')
      commit('CLEAR_ENROLLED_USERS')
      commit('CLEAR_COORDINATORS')
      commit('CLEAR_VENUE')
      commit('CLEAR_VENUES')
      commit('LMS_SUPERADMIN_CUSTOMER_CLEAR')
      commit('LMS_SUPERADMIN_CUSTOMER_CLEAR_CONTACT')
      commit('LMS_SUPERADMIN_CUSTOMER_SUBSCRIPTION_CLEAR')
      commit('LMS_SUPERADMIN_CUSTOMERS_CLEAR')
      commit('LMS_SUPERADMIN_WORKSPACE_CLEAR')
      commit('LMS_SUPERADMIN_WORKSPACES_CLEAR')
      commit('LMS_USER_CLEAR_WORKSPACE')
      commit('LMS_USER_CLEAR_WORKSPACES')
      commit('LMS_USER_ACCOUNT_CLEAR')
      commit('LMS_USER_ACCOUNT_ACTIVITYLOG_CLEAR')
      commit('LMS_SUPERADMIN_USER_PROFILES_CLEAR')

      if (!preventRedirect) {
        dispatch('authUserSetForceRedirect', true)
        router.push({ name: 'auth-login' })
      }
    },
    authUserLogoutSecondStage ({ dispatch }, data) {
      const secondStageToken = TokenHelper.getToken('secondStageToken')
      const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
      const preventRedirect = data ? data.preventRedirect : false
      const allowLogoutOldLMS = data ? data.allowLogoutOldLMS : false

      if (allowLogoutOldLMS) {
        dispatch('authUserRemoveSecondStageToken')
        const lmslogouturl = secondStageTokenPayload.lmslogouturl
        location.replace(lmslogouturl)
        return
      } else {
        dispatch('defaultWorkspaceClear')
        dispatch('ebsRequestsClear')
        dispatch('authUserLoginSSO')
      }
      if (!preventRedirect) {
        dispatch('authUserSetForceRedirect', true)
        router.push({ name: 'lms-user-workspace-list' })
      }
    },
    authUserSignUpPreRegisterCheckExists (ctx, value) {
      const username = value
      const password = ''
      const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
      const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID

      const authenticationData = {
        Username: username,
        Password: password
      }
      const poolData = {
        UserPoolId: userPoolId,
        ClientId: clientId
      }
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      const userData = {
        Username: username,
        Pool: userPool
      }
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onFailure: function (error) {
            if (error.code === 'UserNotFoundException') {
              reject(error.code)
            } else {
              resolve(error.code)
            }
          }
        })
      })
    },
    authUserSignUpPreRegisterUUID (ctx, value) {
      return new Promise((resolve, reject) => {
        if (value) {
          // If value is passed to the function return it immediately
          resolve(value)
        } else {
          return axios({
            method: 'post',
            url: `${api.sso}/v2/user/pre-register`
          }).then((response) => {
            resolve(response.data.id)
          }).catch((error) => {
            reject(error.response)
          })
        }
      })
    },
    authUserSignUpInvitationUUID ({ rootState }) {
      const queryInvitationUserId = rootState.route.query.userId
      return new Promise((resolve, reject) => {
        if (queryInvitationUserId) {
          resolve(queryInvitationUserId)
        } else {
          reject(new Error('no userId in route query'))
        }
      })
    },
    authUserSignUpSSO (ctx, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `${api.sso}/v2/user/register`,
          data: {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress
          }
        }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    authUserSignUpVerified ({ getters, dispatch }) {
      return new Promise((resolve, reject) => {
        const invitationId = getters.getAuthUserSignUpInvitation
        const password = getters.getAuthUserSignUpPassword
        const emailAddress = getters.getAuthUserSignUpEmail

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/sign-up`,
          data: {
            invitationId,
            password
          }
        }).then((response) => {
          dispatch('lmsUserInvitationAccept', invitationId)
          dispatch('defaultNotificationToastsAdd', {
            title: 'Sign up',
            message: `Your account has been created successfully. Use your email address and password to log in.`,
            status: 'success'
          })
          dispatch('authUserClearSignup')
          router.push({ name: 'auth-login' })
          dispatch('authUserSetLoginUsername', emailAddress)
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Sign up',
            message: `You can't sign up because invitation is not valid. Please try again or contact an administrator.`,
            status: 'error'
          })
          dispatch('authUserSetSignUpError', error)
          reject(error.response)
        })
      })
    },
    authUserSignUp ({ commit, getters, dispatch, rootState }) {
      commit('AUTH_USER_CLEAR_SIGNUP_ERROR')
      const preRegisterCheckExists = dispatch('authUserSignUpPreRegisterCheckExists', getters.getAuthUserSignUpEmail)
      const isRouteQueryInvitation = rootState.route.query.invitation && rootState.route.query.userId
      const handleRegisterUUID = isRouteQueryInvitation ? dispatch('authUserSignUpInvitationUUID') : dispatch('authUserSignUpPreRegisterUUID')

      preRegisterCheckExists.catch(() => {
        handleRegisterUUID.then((value) => {
          const userName = value
          const email = getters.getAuthUserSignUpEmail
          const firstName = getters.getAuthUserSignUpFirstName
          const lastName = getters.getAuthUserSignUpLastName
          const password = getters.getAuthUserSignUpPassword
          const marketingAgree = String(getters.getAuthUserSignUpMarketingAgree)
          const dataEmail = {
            Name: 'email',
            Value: email
          }
          const dataFirstName = {
            Name: 'name',
            Value: firstName
          }
          const dataLastName = {
            Name: 'family_name',
            Value: lastName
          }
          const dataMarketingAgree = {
            Name: 'custom:marketing_agree',
            Value: marketingAgree
          }
          const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
          const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID
          const poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId
          }
          const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
          let attributeList = []

          attributeList.push(dataEmail, dataFirstName, dataLastName, dataMarketingAgree)

          userPool.signUp(userName, password, attributeList, null, function (error) {
            if (error) {
              alert(error.message || JSON.stringify(error))
              commit('AUTH_USER_SET_SIGNUP_ERROR', error.message)
              dispatch('defaultNotificationToastsAdd', {
                title: 'Account create',
                message: `There were errors while creating the new account.`,
                status: 'error'
              })
              eventbus.$emit('stop-user-signup')
              return
            }

            if (isRouteQueryInvitation) {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Account create',
                message: `You have successfully created a new account. Please confirm your address email first.`,
                status: 'success'
              })
              commit('AUTH_USER_CLEAR_SIGNUP')
              router.push({ name: 'auth-login' })
            } else {
              dispatch('authUserSignUpSSO', {
                id: userName,
                firstName: firstName,
                lastName: lastName,
                emailAddress: email
              }).then(() => {
                dispatch('defaultNotificationToastsAdd', {
                  title: 'Account create',
                  message: `You have successfully created a new account. Please confirm your address email first.`,
                  status: 'success'
                })
                commit('AUTH_USER_CLEAR_SIGNUP')
                router.push({ name: 'auth-login' })
              }).catch(() => {
                dispatch('defaultNotificationToastsAdd', {
                  title: 'Account create',
                  message: `Some errors occurred while creating a new account. Please try again.`,
                  status: 'error'
                })
              }).finally(() => {
                eventbus.$emit('stop-user-signup')
              })
            }
          })
        })
      }).then(() => {
        commit('AUTH_USER_SET_SIGNUP_ERROR', i18n.t('store.auth.commitExistsError'))
        dispatch('defaultNotificationToastsAdd', {
          title: 'Account create',
          message: `User already exists. Please try again with different details.`,
          status: 'error'
        })
        eventbus.$emit('stop-user-signup')
      })
    },
    authUserSelfRegistration ({ commit, state, rootState }, data) {
      return new Promise((resolve, reject) => {
        commit('AUTH_USER_CLEAR_SELFREGISTRATION_ERROR')

        const stateCustom = JSON.parse(JSON.stringify(state.custom))
        const key = data || rootState.route.params.key
        const firstName = stateCustom.selfRegistration.firstName
        const lastName = stateCustom.selfRegistration.lastName
        const emailAddress = stateCustom.selfRegistration.emailAddress
        const password = stateCustom.selfRegistration.password
        const orgUnit = stateCustom.selfRegistration.orgUnit
        const groups = stateCustom.selfRegistration.groups
        const customFields = stateCustom.selfRegistration.customFields

        let rewrittenCustomFields = {}
        for (let field of customFields) {
          if (field.value) {
            rewrittenCustomFields[field.id] = field.value
          }
        }

        return axios({
          method: 'post',
          url: `${api.sso}/self-registration/register-user`,
          data: {
            key,
            firstName,
            lastName,
            emailAddress,
            password,
            orgUnit,
            groups,
            customFields: rewrittenCustomFields
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserConfirmSelfRegistration (ctx, data) {
      return new Promise((resolve, reject) => {
        const userId = data.userId
        const code = data.code

        return axios({
          method: 'post',
          url: `${api.sso}/self-registration/confirm-signup`,
          data: {
            userId,
            code
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserValidateKey ({ commit }, key) {
      return new Promise((resolve, reject) => {
        commit('AUTH_USER_CLEAR_VALIDATEKEY_ERROR')

        return axios({
          method: 'get',
          url: `${api.sso}/self-registration/key-details/${key}`
        }).then(response => {
          const isValid = response.data.isValid
          if (isValid) {
            resolve(response)
            commit('LMS_SUPERADMIN_WORKSPACE_REGISTER_KEY_GET', response.data)
          } else {
            commit('AUTH_USER_SET_VALIDATEKEY_ERROR', 'This key is not valid. Please contact your administrator for another key.')
            reject(new Error('keyNotValid'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserResetPasswordSendCode ({ commit, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('AUTH_USER_CLEAR_RESETPASSWORD_ERROR')

        const username = getters.getAuthUserResetPasswordEmail
        const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
        const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID

        const poolData = {
          UserPoolId: userPoolId,
          ClientId: clientId
        }
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const userData = {
          Username: username,
          Pool: userPool
        }
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
        cognitoUser.forgotPassword({
          onSuccess: () => {
            dispatch('authUserSetResetPasswordError')
            dispatch('defaultNotificationToastsAdd', {
              title: 'Reset password',
              message: `The verification code has been sent to your email address. Please check your inbox.`,
              status: 'success'
            })
            resolve()
          },
          onFailure: (error) => {
            let errorMessage
            if (error.code === 'UserNotFoundException') {
              errorMessage = i18n.t('store.auth.errorMessageNoExisting')
            } else if (error.code === 'LimitExceededException') {
              errorMessage = i18n.t('store.auth.errorMessageTryAfter')
            } else {
              errorMessage = i18n.t('store.auth.errorMessageTryAgain')
            }
            commit('AUTH_USER_SET_RESETPASSWORD_ERROR', errorMessage)

            dispatch('defaultNotificationToastsAdd', {
              title: 'Account create',
              message: `There were errors while trying to reset the password.`,
              status: 'error'
            })
            reject(error)
          }
        })
      })
    },
    authUserResetPasswordConfirmNewPassword ({ commit, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('AUTH_USER_CLEAR_RESETPASSWORD_ERROR')

        const username = getters.getAuthUserResetPasswordEmail
        const code = getters.getAuthUserResetPasswordCode
        const newPassword = getters.getAuthUserResetPasswordNewPassword
        const userPoolId = process.env.VUE_APP_COGNITO_USERPOOL_ID
        const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID

        const poolData = {
          UserPoolId: userPoolId,
          ClientId: clientId
        }
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const userData = {
          Username: username,
          Pool: userPool
        }
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
        cognitoUser.confirmPassword(code, newPassword, {
          onSuccess: () => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Password reset',
              message: `You have successfully reset the password. You can now log in using new details.`,
              status: 'success'
            })
            commit('AUTH_USER_CLEAR_RESETPASSWORD')
            router.push({ name: 'auth-login' })
            resolve()
          },
          onFailure: (error) => {
            let errorMessage
            if (error.code === 'CodeMismatchException') {
              errorMessage = i18n.t('store.auth.errorInvalidVerification')
            } else if (error.code === 'InvalidParameterException') {
              errorMessage = i18n.t('store.auth.errorPasswordFailed')
            } else if (error.code === 'LimitExceededException') {
              errorMessage = i18n.t('store.auth.errorAttemptLimit')
            } else if (error.code === 'ExpiredCodeException') {
              errorMessage = 'Invalid code provided, please request a code again.'
            } else {
              errorMessage = i18n.t('store.auth.errorPleaseTryAgain')
            }
            commit('AUTH_USER_SET_RESETPASSWORD_ERROR', errorMessage)
            dispatch('defaultNotificationToastsAdd', {
              title: 'Password reset',
              message: `There were errors while trying to reset the password.`,
              status: 'error'
            })
            reject(error.code)
          }
        })
      })
    },
    authUserUpdateRole (ctx, data) {
      return new Promise((resolve, reject) => {
        const userId = data.userId
        const role = data.role
        const customerId = data.customerId || ''
        const method = data.method || 'post'

        axios({
          method: method,
          url: `${api.sso}/v2/user-role`,
          data: {
            userId,
            role,
            customerId
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    authUserCheckRole ({ getters }, data) {
      return new Promise((resolve, reject) => {
        const role = data.role
        const userId = data.userId || getters.getAuthUserFirstStageTokenDataID || getters.getAuthUserSecondStageTokenDataUserId
        return axios({
          method: 'get',
          url: `${api.sso}/v2/users/${userId}`
        }).then(response => {
          const roles = response.data.roles
          if (roles.includes(role)) {
            resolve()
          } else {
            reject(new Error(i18n.t('store.auth.errorUserHasNoRole')))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserMfaVerifySoftwareToken ({ dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        const userId = data ? data.userId : getters.getAuthUserFirstStageTokenDataID
        const code = data ? data.code : getters.getAuthUserMfaCode
        const token = TokenHelper.getToken('accessToken')

        axios({
          method: 'post',
          url: `${api.sso}/v2/cognito/verify-mfa-software-token`,
          data: {
            userId,
            code,
            token
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: '2FA enable',
            message: `You have successfully enabled two factor authentication security.`,
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('authUserSetMfaError', i18n.t('store.auth.errorSomethingWentWrong'))
          reject(error)
        })
      })
    },
    authUserGetUserSSO (context, value) {
      return new Promise((resolve, reject) => {
        const userId = value
        axios({
          method: 'get',
          url: `${api.sso}/v2/users/${userId}`
        }).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    authUserGetSubdomain (ctx, value) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/subdomains/${value}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserGetUserProfiles (ctx, value) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.subscriptionmanager}/subdomains/${value}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    authUserImpersonateUser ({ dispatch, getters }, userProfileId) {
      return new Promise((resolve, reject) => {
        const originWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId

        axios({
          method: 'post',
          url: `${api.sso}/v2/impersonate/${userProfileId}?originWorkspaceId=${originWorkspaceId}`
        }).then((response) => {
          dispatch('authUserSetSecondStageToken', response.data)
          router.push({ name: 'dashboard' })
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    authUserUnimpersonateUser ({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.sso}/v2/unimpersonate`
        }).then((response) => {
          dispatch('authUserSetSecondStageToken', response.data)
          dispatch('defaultWorkspaceClear')
          router.push({ name: 'lms-superadmin-user-list' })
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    //
    authUserSetLoginUsername ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_USERNAME', data)
    },
    authUserSetLoginPassword ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_PASSWORD', data)
    },
    authUserSetLoginError ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_ERROR', data)
    },
    authUserSetLoginMfaChallenge ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_MFACHALLENGE', data)
    },
    authUserSetLoginMfaCode ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_MFACODE', data)
    },
    authUserSetLoginNewPasswordChallenge ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_NEWPASSWORDCHALLENGE', data)
    },
    authUserSetLoginNewPassword ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_NEWPASSWORD', data)
    },
    authUserSetLoginNewPasswordConfirm ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_NEWPASSWORDCONFIRM', data)
    },
    authUserSetLoginStage ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_STAGE', data)
    },
    authUserSetLoginAuthOptions ({ commit }, data) {
      commit('AUTH_USER_SET_LOGIN_AUTHOPTIONS', data)
    },
    authUserSetSignUpInvitation ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_INVITATION', data)
    },
    authUserSetSignUpId ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_ID', data)
    },
    authUserSetSignUpEmail ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_EMAIL', data)
    },
    authUserSetSignUpFirstName ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_FIRSTNAME', data)
    },
    authUserSetSignUpLastName ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_LASTNAME', data)
    },
    authUserSetSignUpPassword ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_PASSWORD', data)
    },
    authUserSetSignUpPasswordConfirm ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_PASSWORDCONFIRM', data)
    },
    authUserSetSignUpMarketingAgree ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_MARKETINGAGREE', data)
    },
    authUserSetSignUpError ({ commit }, data) {
      commit('AUTH_USER_SET_SIGNUP_ERROR', data)
    },
    authUserSetValidateKeyKey ({ commit }, data) {
      commit('AUTH_USER_SET_VALIDATEKEY_KEY', data)
    },
    authUserSetValidateKeyError ({ commit }, data) {
      commit('AUTH_USER_SET_VALIDATEKEY_ERROR', data)
    },
    authUserSetSelfRegistrationKey ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_KEY', data)
    },
    authUserSetSelfRegistrationEmailAddress ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_EMAILADDRESS', data)
    },
    authUserSetSelfRegistrationFirstName ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_FIRSTNAME', data)
    },
    authUserSetSelfRegistrationLastName ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_LASTNAME', data)
    },
    authUserSetSelfRegistrationCustomField ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_CUSTOMFIELD', data)
    },
    authUserSetSelfRegistrationPassword ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_PASSWORD', data)
    },
    authUserSetSelfRegistrationPasswordConfirm ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_PASSWORDCONFIRM', data)
    },
    authUserSetSelfRegistrationOrgUnit ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_ORGUNIT', data)
    },
    authUserSetSelfRegistrationGroups ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_GROUPS', data)
    },
    authUserSetSelfRegistrationError ({ commit }, data) {
      commit('AUTH_USER_SET_SELFREGISTRATION_ERROR', data)
    },
    authUserSetResetPasswordEmail ({ commit }, data) {
      commit('AUTH_USER_SET_RESETPASSWORD_EMAIL', data)
    },
    authUserSetResetPasswordCode ({ commit }, data) {
      commit('AUTH_USER_SET_RESETPASSWORD_CODE', data)
    },
    authUserSetResetPasswordError ({ commit }, data) {
      commit('AUTH_USER_SET_RESETPASSWORD_ERROR', data)
    },
    authUserSetResetPasswordNewPassword ({ commit }, data) {
      commit('AUTH_USER_SET_RESETPASSWORD_NEWPASSWORD', data)
    },
    authUserSetResetPasswordNewPasswordConfirm ({ commit }, data) {
      commit('AUTH_USER_SET_RESETPASSWORD_NEWPASSWORDCONFIRM', data)
    },
    authUserSetMfaSecret ({ commit }, data) {
      commit('AUTH_USER_SET_MFA_SECRET', data)
    },
    authUserSetMfaCode ({ commit }, data) {
      commit('AUTH_USER_SET_MFA_CODE', data)
    },
    authUserSetMfaError ({ commit }, data) {
      commit('AUTH_USER_SET_MFA_ERROR', data)
    },
    authUserSetForceRedirect ({ commit }, data) {
      commit('AUTH_USER_SET_FORCEREDIRECT', data)
    },
    authUserSetHasEmptyRequiredCustomFields ({ commit }, data) {
      commit('AUTH_USER_SET_HASEMPTYREQUIREDCUSTOMFIELDS', data)
    },
    //
    authUserCheckIsTrainer: ({ commit, state }) => {
      return new Promise(resolve => {
        const data = {
          userId: state.data.secondStageTokenData.userId,
          domain: state.data.secondStageTokenData.domain
        }
        axios({
          method: 'GET',
          url: `${api.classroom}/trainers-check/${data.userId}/${data.domain}`
        }).then(() => {
          commit('AUTH_USER_SET_ISTRAINER', true)
          resolve()
        }).catch(() => {
          commit('AUTH_USER_SET_ISTRAINER', false)
          resolve()
        })
      })
    },
    authUserHandleLandingRedirect: ({ rootState, dispatch }) => {
      const sso = rootState.route.query.sso
      const token = rootState.route.query.token
      const target = rootState.route.query.target
      let redirect = rootState.route.query.redirect
      const mfaEnabled = store.getters.getAuthUserHasMfaEnabled

      if (target) {
        let routeName
        let routeParams
        let eventId = rootState.route.query.eventId
        let sessionId = rootState.route.query.sessionId
        let hash = rootState.route.query.hash

        switch (target) {
          case 'calendar':
            routeName = 'home'
            break
          case 'event':
            routeName = 'ebs-event-show'
            routeParams = { id: eventId }
            break
          case 'session':
            routeName = 'ebs-session-show'
            routeParams = { eventId: eventId, sessionId: sessionId }
            break
        }

        router.push({ name: routeName, params: routeParams, hash: hash ? `#${hash}` : undefined })
      } else if (redirect) {
        if (
          redirect.includes('/notifications') ||
        redirect.includes('/venues')
        ) {
          redirect = '/admin' + redirect
        } else if (
          redirect.includes('/calendar')
        ) {
          redirect = '/catalogue/events?listView=calendar'
        } else if (
          (redirect.includes('/events') && redirect.includes('/iframe/booking')) ||
        redirect.includes('/session')
        ) {
          redirect = '/catalogue' + redirect
        } else if (
          redirect.includes('/events')
        ) {
          redirect = '/catalogue/events?listView=table'
        } else if (
          redirect.includes('/trainers')
        ) {
          redirect = '/users' + redirect
        }
        if (token) {
          axios({
            method: 'POST',
            url: `${api.sso}/jwt/validate`,
            data: {
              token: token
            }
          }).then((response) => {
            const token = response.data
            dispatch('authUserRemoveFirstStageToken')
            dispatch('authUserSetSecondStageToken', token).then(() => {
              dispatch('authUserCheckIsTrainer')
              router.push(redirect)
            })
          }).catch((error) => {
            router.push({ name: '404' })
            store.dispatch('defaultNotificationToastsAdd', {
              title: 'Error',
              message: error.response.data.errors.message,
              status: 'error'
            })
          })
        } else {
          router.push(redirect)
        }
      } else if (sso) {
        Cookies.set('isSSO', true)
        dispatch('authUserRemoveFirstStageToken')
        dispatch('authUserSetSecondStageToken', token)

        store.dispatch('lmsUserGetWorkspaces').then(response => {
          const user = new User()
          const profiles = response.data
          const profilesWithRoles = profiles.length ? profiles.filter(obj => obj.roles.length) : []
          const userIsSuperadmin = user.isSuperadmin()
          const userIsAdmin = profiles && profiles.length ? profiles.find(obj => obj.customerRoles && obj.customerRoles.includes('master_administrator')) : false
          const userIsTrainer = profiles && profiles.length ? profiles.find(obj => obj.customerRoles && obj.customerRoles.includes('trainer')) : false

          if (profilesWithRoles.length === 0) {
            if (userIsAdmin) {
              Cookies.set('customerRoleArea', 'master_administrator')
            } else if (userIsTrainer) {
              Cookies.set('customerRoleArea', 'trainer')
            }
            if (userIsSuperadmin) {
              if (mfaEnabled) {
                router.push({ name: 'lms-superadmin-user-list' })
              } else {
                router.push({ name: 'lms-user-account-security' })
              }
            } else if (userIsAdmin) {
              const workspaceId = profiles[0] ? profiles[0].id : undefined
              store.dispatch('authUserLoginSSOWorkspace', workspaceId).then(() => {
                router.push({ name: 'lms-superadmin-user-list' })
              }).catch(() => {
                router.push({ name: 'default' })
              })
            } else if (userIsTrainer) {
              router.push({ name: 'dashboard-trainer' })
            } else {
              router.push({ name: 'lms-user-account-personal' })
            }
          } else if (profilesWithRoles.length === 1 && !userIsSuperadmin && !userIsAdmin && !userIsTrainer) {
            const workspaceId = profilesWithRoles[0] ? profilesWithRoles[0].id : undefined
            store.dispatch('authUserLoginSSOWorkspace', workspaceId).then(() => {
              if (userIsSuperadmin) {
                if (mfaEnabled) {
                  router.push({ name: 'lms-superadmin-user-list' })
                } else {
                  router.push({ name: 'lms-user-account-security' })
                }
              } else {
                router.push({ name: 'default' })
              }
            }).catch(() => {
              router.push({ name: 'default' })
            })
          } else {
            if (userIsSuperadmin) {
              if (mfaEnabled) {
                router.push({ name: 'lms-superadmin-user-list' })
              } else {
                router.push({ name: 'lms-user-account-security' })
              }
            } else {
              router.push({ name: 'default' })
            }
          }
        })
      } else {
        router.push({ name: 'default' })
      }
    }
  }
}

export default authUser
