import axios from 'axios'
import api from '@/api'
import { User } from '@/User'

const data = {
  data: null
}

const lmsSuperadminUserProfiles = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminUserProfiles (state) {
      return state.data
    },
    getLmsSuperadminUserProfilesWorkspaceIds (state) {
      const rawItems = state.data
      return rawItems ? rawItems.map(obj => obj.workspaceId) : []
    },
    getLmsSuperadminUserProfilesActive (state) {
      const profiles = JSON.parse(JSON.stringify(state.data))
      return profiles.length ? profiles.filter(obj => obj.status === 'active' && obj.workspaceName) : []
    },
    getLmsSuperadminUserProfilesActiveRoles (state, getters) {
      let roles = []
      const profiles = getters.getLmsSuperadminUserProfilesActive

      for (let i in profiles) {
        for (let j in profiles[i].roles) {
          const profileRole = profiles[i].roles[j]

          if (!roles.includes(profileRole)) {
            roles.push(profileRole)
          }
        }
      }

      return roles
    },
    getLmsSuperadminUserProfilesWithMARole (state, getters) {
      const profiles = getters.getLmsSuperadminUserProfilesActive
      let selectedCustomerIds = []

      for (let i in profiles) {
        if (profiles[i].roles.includes('master_administrator') && !selectedCustomerIds.includes(profiles[i].customerId)) {
          selectedCustomerIds.push(profiles[i].customerId)
        }
      }

      return profiles.filter(obj => selectedCustomerIds.includes(obj.customerId))
    },
    getLmsSuperadminUserProfilesWithLEARoleInCustomerWorkspaceId: (state, getters) => customerId => {
      const profiles = getters.getLmsSuperadminUserProfilesActive
      const profilesFiltered = profiles && profiles.length ? profiles.filter(obj => obj.roles.includes('learning_experience_administrator') && obj.customerId === customerId) : []
      const profilesMapped = profilesFiltered && profilesFiltered.length ? profilesFiltered.map(obj => obj.workspaceId) : []

      return profilesMapped
    },
    getLmsSuperadminUserProfilesWithUARole (state, getters) {
      const profiles = getters.getLmsSuperadminUserProfilesActive
      return profiles.filter(obj => obj.roles.includes('user_administrator'))
    },
    getLmsSuperadminUserProfilesWithUAOrMARole (state, getters) {
      const profilesWithUARole = getters.getLmsSuperadminUserProfilesWithUARole
      const profilesWithMARole = getters.getLmsSuperadminUserProfilesWithMARole
      let profilesArr = [...profilesWithUARole, ...profilesWithMARole]
      profilesArr = profilesArr.filter((obj, index) => {
        return profilesArr.findIndex(findObj => findObj.workspaceId === obj.workspaceId) === index
      })

      return profilesArr
    },
    getLmsSuperadminUserProfilesCustomersWithUAOrMARole (state, getters) {
      const profilesWithUARole = getters.getLmsSuperadminUserProfilesWithUARole
      const profilesWithMARole = getters.getLmsSuperadminUserProfilesWithMARole
      let customersArr = [...profilesWithUARole, ...profilesWithMARole]
      customersArr = customersArr.filter((obj, index) => {
        return customersArr.findIndex(findObj => findObj.customerId === obj.customerId) === index
      })

      return customersArr
    },
    getLmsSuperadminUserProfilesCustomersWithMARole (state, getters) {
      const profilesWithMARole = getters.getLmsSuperadminUserProfilesWithMARole
      let customersArr = profilesWithMARole
      customersArr = customersArr.filter((obj, index) => {
        return customersArr.findIndex(findObj => findObj.customerId === obj.customerId) === index
      })

      return customersArr
    },
    getLmsSuperadminUserProfilesMappedLabelValue: (state, getters) => {
      let user = new User()
      let rawItems
      if (user.isSiteAdmin()) {
        rawItems = [...getters.getLmsSuperadminUserProfilesWithMARole, ...getters.getLmsSuperadminUserProfilesWithUARole]
      } else if (user.isUserAdministrator()) {
        rawItems = getters.getLmsSuperadminUserProfilesWithUARole
      } else {
        rawItems = getters.getLmsSuperadminUserProfilesActive
      }
      rawItems = rawItems.filter((obj, index) => {
        return rawItems.findIndex(findObj => findObj.workspaceId === obj.workspaceId) === index
      })

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.workspaceName + item.workspaceId,
          customLabel: `${item.workspaceName} (${item.customerName})`,
          rawLabel: item.workspaceName,
          value: item.workspaceId,
          customerName: item.customerName,
          customerId: item.customerId
        }
      })
    },
    getLmsSuperadminUserProfilesMappedIdName (state, getters) {
      let user = new User()
      let rawItems
      if (user.isSiteAdmin()) {
        rawItems = [...getters.getLmsSuperadminUserProfilesWithMARole, ...getters.getLmsSuperadminUserProfilesWithUARole]
      } else if (user.isUserAdministrator()) {
        rawItems = getters.getLmsSuperadminUserProfilesWithUARole
      } else {
        rawItems = getters.getLmsSuperadminUserProfilesActive
      }
      rawItems = rawItems.filter((obj, index) => {
        return rawItems.findIndex(findObj => findObj.workspaceId === obj.workspaceId) === index
      })

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.workspaceId,
          name: item.workspaceName,
          customer: item.customerName
        }
      })
    },
    getLmsSuperadminUserProfilesCustomersMappedLabelValue: (state, getters) => {
      let rawItems = getters.getLmsSuperadminUserProfilesCustomersWithUAOrMARole
      rawItems = rawItems.filter((obj, index) => {
        return rawItems.findIndex(findObj => findObj.customerId === obj.customerId) === index
      })

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.customerName + item.customerId,
          customLabel: item.customerName,
          value: item.customerId
        }
      })
    },
    getLmsSuperadminUserProfilesCustomersMappedIdName (state, getters) {
      let rawItems = getters.getLmsSuperadminUserProfilesCustomersWithUAOrMARole
      rawItems = rawItems.filter((obj, index) => {
        return rawItems.findIndex(findObj => findObj.customerId === obj.customerId) === index
      })

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.customerId,
          name: item.customerName
        }
      })
    },
    getLmsSuperadminUserProfilesByCustomerMappedLabelValue: (state, getters) => customerId => {
      let rawItems = getters.getLmsSuperadminUserProfilesWithUAOrMARole
      rawItems = rawItems.filter((obj, i) => {
        return rawItems.findIndex(findObj => findObj.workspaceId === obj.workspaceId) === i
      })
      rawItems = rawItems.filter((obj) => {
        return obj.customerId === customerId
      })

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.workspaceName + item.workspaceId,
          customLabel: `${item.workspaceName} (${item.customerName})`,
          rawLabel: item.workspaceName,
          value: item.workspaceId,
          customerName: item.customerName,
          customerId: item.customerId
        }
      })
    },
    getLmsSuperadminUserProfilesByCustomerMappedIdName: (state, getters) => customerId => {
      let rawItems = getters.getLmsSuperadminUserProfilesWithUAOrMARole
      rawItems = rawItems.filter((obj, i) => {
        return rawItems.findIndex(findObj => findObj.workspaceId === obj.workspaceId) === i
      })
      rawItems = rawItems.filter((obj) => {
        return obj.customerId === customerId
      })

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.workspaceId,
          name: item.workspaceName,
          customer: item.customerName
        }
      })
    },
    getLmsSuperadminUserProfilesNumberOfWorkspacesByCustomer: (state) => customerId => {
      let workspacesArr = []
      let rawItems = JSON.parse(JSON.stringify(state.data))
      rawItems = rawItems.filter(obj => {
        return obj.customerId === customerId
      })

      for (let item of rawItems) {
        if (workspacesArr.findIndex(obj => obj.workspaceId === item.workspaceId) === -1) {
          workspacesArr.push(item)
        }
      }

      return workspacesArr.length
    }
  },
  mutations: {
    LMS_SUPERADMIN_USER_PROFILES_SET (state, payload) {
      state.data = JSON.parse(JSON.stringify(payload))
    },
    LMS_SUPERADMIN_USER_PROFILES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    }
  },
  actions: {
    lmsSuperadminUserProfilesGet ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.sso}/v2/user-profiles`
        }).then(response => {
          commit('LMS_SUPERADMIN_USER_PROFILES_SET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminUserProfilesClear ({ commit }) {
      commit('LMS_SUPERADMIN_USER_PROFILES_CLEAR')
    }
  }
}

export default lmsSuperadminUserProfiles
